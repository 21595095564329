import { ChangeEvent } from 'react';

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const Cin7ServerBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>
        Details for the Cin7 API integration
        <br />
        <a href="https://inventory.dearsystems.com/ExternalApi#tab-shop-settings" target="_blank" rel="noreferrer">
          <small>https://inventory.dearsystems.com/ExternalApi</small>
        </a>
      </span>
    </div>
    <FormInput
      id="account id"
      name="Account Id"
      label="Account Id"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'cin7.accountId')?.Value}
      placeholder="Account Id"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'cin7.accountId')}
    />
    <FormInput
      id="application key"
      name="Application Key"
      label="Application Key"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'cin7.applicationKey')?.Value}
      placeholder="Application Key"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'cin7.applicationKey')}
    />
  </FormSection>
);

export default Cin7ServerBusiness2CloudConnectorConfiguration;
