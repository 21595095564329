import { ChangeEvent } from 'react';

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const GaapBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the GAAP API integration</span>
    </div>
    <FormInput
      id="API Key"
      name="API Key"
      label="API Key"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'gaap.apiKey')?.Value}
      placeholder="Account Id"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'gaap.apiKey')}
    />
    <FormInput
      id="Node"
      name="Node"
      label="Node"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'gaap.node')?.Value}
      placeholder="Node"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'gaap.node')}
    />
    <FormInput
      id="Starting Date"
      name="Starting Date"
      label="Starting Date (YYYY-MM-DD)"
      required={true}
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'gaap.startingDate')?.Value}
      placeholder="Starting Date (YYYY-MM-DD)"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'gaap.startingDate')}
    />
  </FormSection>
);

export default GaapBusiness2CloudConnectorConfiguration;
