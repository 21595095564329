/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, Fragment, KeyboardEvent, createRef, useContext, useEffect, useState } from 'react';
import { withRouter } from '../routes/withRouter';
import { PAGE_URLS } from '../routes/routes';
import { ApiService } from '../services/apiService';
import { Store } from '../store/Store';
import { REDUCER_ACTION_SET } from '../store/types';
import { Link, useSearchParams } from 'react-router-dom';

import FormSection from './FormSection';
import Form from './Form';
import FormInput from './FormInput';
import twMerge from "./../common/TailwindMerge";

const api = ApiService.getInstance();

const Signin = (props: any) => {
  const store = useContext(Store);
  const formRef = createRef<any>();

  const [searchParams] = useSearchParams();
  const [registrationMessage, setRegistrationMessage] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [errorTop, setErrorTop] = useState(0);
  const [errorSticky, setErrorSticky] = useState(false);
  const [hideError, setHideError] = useState(false);
  const [loggedInName, setLoggedInName] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [showLoginLoader, setShowLoginLoader] = useState(false);

  const onScroll = () => formRef?.current && setErrorTop(formRef.current.getBoundingClientRect().top);

  const onErrorHideClick = () => {
    setHideError(true);
    setTimeout(() => {
      setHideError(false);
      setError('');
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  useEffect(() => setErrorSticky(errorTop < 0), [errorTop]);

  useEffect(() => {
    const provider = searchParams.get('provider');
    const error = searchParams.get('error');

    if (!!error) {
      if (error === 'unauthorised')
        setError(
          `Your <b>${provider || 'external provider'}</b> credentials have not been linked to a user.<br />Please <b>sign up with ${provider || 'the external provider'
          }</b> below and try again.`
        );
      if (error === 'unknown')
        setError(
          `Something seems to have gone wrong while trying to sign in using <b>${provider || 'the external provider'
          }</b>.<br /><br />Please try again.`
        );
    }

    const code = searchParams.get('code');

    if (!!code) {
      login(code);
      return;
    }

    const registration = searchParams.get('registration');

    setRegistrationMessage(registration === 'success');

    let loggedInName =
      searchParams.get('name') ||
      document.cookie
        .split('; ')
        .find((_) => _.startsWith('logged_in_name='))
        ?.split('=')[1];

    if (loggedInName) setLoggedInName(loggedInName);

    setShowLoader(false);
  }, [searchParams]);

  const onUsernameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setUsername(event.currentTarget.value);
  };

  const onPasswordInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setPassword(event.currentTarget.value);
  };

  const onInputKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && username.length && password.length) {
      await login();
    }
  };

  const login = async (code?: string) => {
    setShowLoginLoader(true);

    try {
      setError('');

      const resJson: any = !!code ? await api.loginWithCode(code) : await api.loginWithUsernamePassword(username, password);

      if (resJson.verificationRequired) {
        props.router.navigate(`${PAGE_URLS.SIGNIN_CONFIRM}?reference=${resJson.verificationReference}`);
      }

      if (resJson.statusCode && resJson.statusCode === 404) {
        throw new Error('not found');
      }

      if (!resJson?.accessToken || (resJson.statusCode && resJson.statusCode === 401)) {
        setError('Please check your email address and password.');
        setShowLoginLoader(false);
        return;
      }

      api.setJwt(resJson.accessToken);

      store.dispatch({
        type: REDUCER_ACTION_SET.SET_JWT_TOKEN,
        payload: resJson.accessToken,
      });

      store.dispatch({
        type: REDUCER_ACTION_SET.SET_USER,
        payload: {
          firstName: resJson.firstName,
          lastName: resJson.lastName,
          role: resJson.role,
          roleLevel: resJson.roleLevel,
          email: resJson.email,
          requireMultiFactorAuthentication: resJson.requireMultiFactorAuthentication,
          verificationDevices: resJson.verificationDevices,
        },
      });

      document.cookie = 'logged_in=true';
      document.cookie = `logged_in_name=${resJson.firstName}`;

      const returnUrl = searchParams.get('returnUrl');

      if (!!returnUrl) {
        props.router.navigate(decodeURIComponent(returnUrl));
      } else {
        props.router.navigate(PAGE_URLS.HOME);
      }
    } catch (err) {
      setError('Something seems to have gone wrong, please try again.');
    }

    setShowLoginLoader(false);
  };

  if (showLoader) return <div></div>;

  return (
    <div className={`login-container container pb-5 ${process.env.REACT_APP_SIGN_IN_WITH_XERO !== 'true' && 'pt-5'}`}>
      <div className='row justify-content-center'>
        <div className='col-11 col-sm-10 col-md-7 col-lg-5 col-xl-4'>
          <div className='m-4 mt-5'>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <img src='/img/larasoft-logo-dark.svg' alt='Larasoft Logo' height='30px' />
              <span className='text-center mt-4 text-nowrap light-text'>
                {loggedInName ? (
                  registrationMessage ? (
                    <div>
                      Welcome, <b>{loggedInName}</b>
                      <div className='mt-3 small-text d-block'>Please watch out for a welcome email which will provide you with the next steps.</div>
                    </div>
                  ) : (
                    <div>
                      Welcome back, <b>{loggedInName}</b>
                    </div>
                  )
                ) : (
                  <div>
                    Sign in to <b>Business2Cloud</b>
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className='card card-sm'>
            <div className='card-body' ref={formRef}>
              <div className='form'>
                {error && (
                  <div className={`error-message ${hideError ? 'hide' : ''} ${errorSticky ? 'sticky' : ''}`}>
                    <span>
                      <div dangerouslySetInnerHTML={{ __html: error }}></div>
                      <i className='material-symbols-rounded' onClick={onErrorHideClick}>
                        cancel
                      </i>
                    </span>
                  </div>
                )}
                <div>
                  <Form onSubmit={() => login()}>
                    <FormSection>
                      <FormInput
                        id='username'
                        placeholder='Username'
                        required={true}
                        value={username}
                        onChange={onUsernameInputChange}
                        onKeyUp={onInputKeyUp}
                      />
                      <FormInput
                        type='password'
                        id='password'
                        placeholder='Password'
                        required={true}
                        value={password}
                        onChange={onPasswordInputChange}
                        onKeyUp={onInputKeyUp}
                      />
                      <small className='mb-1 m-auto'>
                        <Link to='/reset-password'>Forgot password?</Link>
                      </small>
                      <button
                        className='btn btn-sm btn-secondary justify-content-center flex-grow-1'
                        type='button'
                        disabled={!username.length || !password.length}
                        onClick={() => login()}>
                        Sign in
                      </button>
                    </FormSection>
                    {process.env.REACT_APP_SIGN_IN_WITH_XERO === 'true' && (
                      <FormSection divider={true} dividerLabel='or'>
                        <iframe
                          title='Sign in with Xero'
                          src={`/signin-with-xero.html?api=${process.env.REACT_APP_B2C_API_URL}`}
                          style={{ height: 52, margin: -8 }}
                          sandbox='allow-scripts allow-top-navigation'></iframe>
                      </FormSection>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            {showLoginLoader && (
              <div className='card-loader'>
                <svg className='spinner primary' width='50px' height='50px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
                  <circle className='circle' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
                </svg>
              </div>
            )}
          </div>
          <div className='d-flex flex-column mt-4 gap-2'>
            <span className='d-flex justify-content-center'>
              No account?&nbsp;<Link to='/signup'>Sign up</Link>&nbsp;now.
            </span>
            {process.env.REACT_APP_SIGN_IN_WITH_XERO === 'true' && (
              <Fragment>
                <span className='d-flex divider my-3' data-label='or'></span>
                <span className='d-flex justify-content-center m-auto'>
                  <a 
                    href="https://xerosignup.larasoft.co.za"
                    className={twMerge(
                      "tw-flex tw-gap-4 tw-p-2 tw-pe-3 tw-items-center tw-rounded-lg tw-bg-white tw-ring-1 tw-ring-neutral-300 tw-select-none tw-cursor-pointer tw-transition-all tw-text-sm tw-no-underline tw-text-neutral-600 tw-font-semibold",
                      "hover:tw-ring-2 hover:tw-bg-neutral-100 hover:tw-text-neutral-600")}>
                    <img 
                      src="data:image/svg+xml,%3Csvg viewBox='0 0 45 46' xmlns='http://www.w3.org/2000/svg'%3E %3Ctitle%3EXero%3C/title%3E %3Cpath fill='%2313B5EA' d='M22.457 45.49c12.402 0 22.456-10.072 22.456-22.495C44.913 10.57 34.86.5 22.457.5 10.054.5 0 10.57 0 22.995 0 35.418 10.054 45.49 22.457 45.49' /%3E %3Cpath fill='%23FFFFFF' d='M10.75 22.935l3.832-3.85a.688.688 0 0 0-.977-.965l-3.83 3.833-3.845-3.84a.687.687 0 0 0-.966.979l3.832 3.837-3.83 3.84a.688.688 0 1 0 .964.981l3.84-3.842 3.825 3.827a.685.685 0 0 0 1.184-.473.68.68 0 0 0-.2-.485l-3.83-3.846m22.782.003c0 .69.56 1.25 1.25 1.25a1.25 1.25 0 0 0-.001-2.5c-.687 0-1.246.56-1.246 1.25m-2.368 0c0-1.995 1.62-3.62 3.614-3.62 1.99 0 3.613 1.625 3.613 3.62s-1.622 3.62-3.613 3.62a3.62 3.62 0 0 1-3.614-3.62m-1.422 0c0 2.78 2.26 5.044 5.036 5.044s5.036-2.262 5.036-5.043c0-2.78-2.26-5.044-5.036-5.044a5.046 5.046 0 0 0-5.036 5.044m-.357-4.958h-.21c-.635 0-1.247.2-1.758.595a.696.696 0 0 0-.674-.54.68.68 0 0 0-.68.684l.002 8.495a.687.687 0 0 0 1.372-.002v-5.224c0-1.74.16-2.444 1.648-2.63.14-.017.288-.014.29-.014.406-.015.696-.296.696-.675a.69.69 0 0 0-.69-.688m-13.182 4.127c0-.02.002-.04.003-.058a3.637 3.637 0 0 1 7.065.055H16.2zm8.473-.13c-.296-1.403-1.063-2.556-2.23-3.296a5.064 5.064 0 0 0-5.61.15 5.098 5.098 0 0 0-1.973 5.357 5.08 5.08 0 0 0 4.274 3.767c.608.074 1.2.04 1.81-.12a4.965 4.965 0 0 0 1.506-.644c.487-.313.894-.727 1.29-1.222.006-.01.014-.017.022-.027.274-.34.223-.826-.077-1.056-.254-.195-.68-.274-1.014.156-.072.104-.153.21-.24.315-.267.295-.598.58-.994.802-.506.27-1.08.423-1.69.427-1.998-.023-3.066-1.42-3.447-2.416a3.716 3.716 0 0 1-.153-.58l-.01-.105h7.17c.982-.022 1.51-.717 1.364-1.51z' /%3E %3C/svg%3E" 
                      alt="Xero logo" 
                      className="tw-w-[1.6em]" />
                    Sign up with Xero
                  </a>
                </span>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
