import 'material-icons/iconfont/material-icons.css';
import 'material-symbols';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PAGE_URLS } from './routes/routes';
import { Fragment, useContext } from 'react';
import { Store } from './store/Store';
import { Toaster } from "react-hot-toast";

import Signin from './components/Signin';
import Tokens from './components/Tokens';
import Nav from './components/Nav';
import Home from './components/Home';
import Entities from './components/Entities';
import Areas from './components/Areas';
import LinkAreasSubAreas from './components/LinkAreasSubAreas';
import LinkSubAreasAreas from './components/LinkSubAreasAreas';
import Versions from './components/Versions';
import HelperTables from './components/HelperTables';
import Users from './components/Users';
import SecurityGroups from './components/SecurityGroups';
import AssignUsersToSecurityGroups from './components/AssignUsersToSecurityGroups';
import AssignUsersEntities from './components/AssignUsersEntities';
import AssignEntitiesUsers from './components/AssignEntitiesUsers';
import AssignUsersHelperTables from './components/AssignUsersHelperTables';
import AssignHelperTablesUsers from './components/AssignHelperTablesUsers';
import Xero2SqlRefresh from './components/Xero2SqlRefresh';
import TableSync from './components/TableSync';
import AssignUsersToXero2Sql from './components/AssignUsersToXero2Sql';
import Business2CloudXeroConnectorRefresh from './components/Business2CloudConnectorManagement';
import Business2CloudConnectorConfiguration from './components/Business2CloudConnectorConfiguration';
import Signup from './components/Signup';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPasswordRedeem from './components/ResetPasswordRedeem';
import SignupFinalise from './components/SignupFinalise';
import Approvals from './components/Approvals';
import Admin from './components/Admin';
import GlobalConfiguration from './components/GlobalConfiguration';
import Profile from './components/Profile';
import SigninConfirm from './components/SigninConfirm';
import Clients from "./components/Clients";
import SubAreas from "./components/SubAreas";

function App() {
  const store = useContext(Store).store;

  if (
    window.location.pathname !== '/' &&
    !window.location.pathname.startsWith(PAGE_URLS.SIGNUP) &&
    !window.location.pathname.startsWith(PAGE_URLS.SIGNIN) &&
    !window.location.pathname.startsWith(PAGE_URLS.RESET_PASSWORD) &&
    !window.location.pathname.startsWith(PAGE_URLS.SET_PASSWORD.replace('/:resetPasswordRedeemToken', '')) &&
    !store?.user
  ) {
    window.location.replace(`/?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`);
    return <></>;
  }

  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path={PAGE_URLS.ROOT} element={<Signin />} />
          <Route path={PAGE_URLS.SIGNIN} element={<Signin />} />
          <Route path={PAGE_URLS.SIGNIN_CONFIRM} element={<SigninConfirm />} />
          <Route path={PAGE_URLS.SIGNUP} element={<Signup />} />
          <Route path={PAGE_URLS.SIGNUP_FINALISE} element={<SignupFinalise />} />
          <Route path={PAGE_URLS.RESET_PASSWORD} element={<ResetPasswordRequest />} />
          <Route path={PAGE_URLS.SET_PASSWORD} element={<ResetPasswordRedeem setOnly={true} />} />
          <Route path={PAGE_URLS.RESET_PASSWORD_REDEEM} element={<ResetPasswordRedeem />} />
          <Route
            path={PAGE_URLS.PROFILE}
            element={
              <Nav>
                <Profile />
              </Nav>
            }
          />
          <Route
            path={store.user?.roleLevel <= 1 ? PAGE_URLS.ADMIN_DASHBOARD : PAGE_URLS.ADMIN_USERS}
            element={<Nav>{store.user?.roleLevel <= 1 ? <Admin /> : <Users />}</Nav>}
          />
          <Route
            path={PAGE_URLS.ADMIN_CONFIGURATION}
            element={
              <Nav>
                <GlobalConfiguration />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.ADMIN_APPROVALS}
            element={
              <Nav>
                <Approvals />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.ADMIN_APPROVAL}
            element={
              <Nav>
                <Approvals />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.ADMIN_ORGANISATIONS}
            element={
              <Nav>
                <Clients />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.ADMIN_ORGANISATION}
            element={
              <Nav>
                <Clients />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.HOME}
            element={
              <Nav>
                <Home />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_ENTITIES}
            element={
              <Nav>
                <Entities />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_AREAS}
            element={
              <Nav>
                <Areas />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_SUBAREAS}
            element={
              <Nav>
                <SubAreas />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_AREA_SUBAREAS}
            element={
              <Nav>
                <LinkAreasSubAreas />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_SUBAREA_AREAS}
            element={
              <Nav>
                <LinkSubAreasAreas />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_VERSIONS}
            element={
              <Nav>
                <Versions />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_HELPER_TABLES}
            element={
              <Nav>
                <HelperTables />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.ADMIN_USERS}
            element={
              <Nav>
                <Users />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_SECURITY_GROUPS}
            element={
              <Nav>
                <SecurityGroups />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_USER_SECURITY_GROUPS}
            element={
              <Nav>
                <AssignUsersToSecurityGroups />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_USER_ENTITIES}
            element={
              <Nav>
                <AssignUsersEntities />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_ENTITY_USERS}
            element={
              <Nav>
                <AssignEntitiesUsers />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_USER_HELPER_TABLES}
            element={
              <Nav>
                <AssignUsersHelperTables />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_HELPER_TABLE_USERS}
            element={
              <Nav>
                <AssignHelperTablesUsers />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.XERO_V2_SYNCHRONISE}
            element={
              <Nav>
                <Xero2SqlRefresh />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.XERO_V2_TOKENS}
            element={
              <Nav>
                <Tokens />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.EXCEL_TABLE_SYNCHRONISATION}
            element={
              <Nav>
                <TableSync />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.XERO_V2_ASSIGN_USERS}
            element={
              <Nav>
                <AssignUsersToXero2Sql />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_REFRESH}
            element={
              <Nav>
                <Business2CloudXeroConnectorRefresh />
              </Nav>
            }
          />
          <Route
            path={PAGE_URLS.BUSINESS_2_CLOUD_CONNECTOR_MANAGE}
            element={
              <Nav>
                <Business2CloudConnectorConfiguration />
              </Nav>
            }
          />
        </Routes>
      </Router>
      <Toaster
        containerStyle={{
          zIndex: 99999
        }}
        toastOptions={{
          style: {
            zIndex: 99999
          }
        }} />
    </Fragment>
  );
}

export default App;
