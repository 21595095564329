import { ChangeEvent } from 'react';

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const UnicontaBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the Uniconta API integration</span>
    </div>
    <FormInput
      id="uniconta.username"
      name="Username"
      label="Username"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'uniconta.username')?.Value}
      placeholder="Username"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'uniconta.username')}
    />
    <FormInput
      id="uniconta.password"
      type="password"
      name="Password"
      label="Password"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'uniconta.password')?.Value}
      placeholder="Password"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'uniconta.password')}
    />
  </FormSection>
);

export default UnicontaBusiness2CloudConnectorConfiguration;
