import { ChangeEvent } from 'react';
import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const WooCommerceBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the WooCommerce integration</span>
    </div>
    <FormInput
      required
      id="URI"
      name="Uri"
      label="Uri"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'woo.uri')?.Value}
      placeholder="Uri"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'woo.uri')}
    />
    <FormInput
      required
      id="Key"
      name="Key"
      label="Key"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'woo.key')?.Value}
      placeholder="Key"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'woo.key')}
    />
    <FormInput
      required
      id="Secret"
      name="Secret"
      label="Secret"
      type="password"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'woo.secret')?.Value}
      placeholder="Secret"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'woo.secret')}
    />
    <FormInput
      id="Start Date"
      name="Start Date (YYYY-MM-DD)"
      label="Start Date (YYYY-MM-DD)"
      required={true}
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'woo.startDate')?.Value}
      placeholder="Start Date (YYYY-MM-DD)"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'woo.startDate')}
    />
    <FormInput
      id="Excluded Orders"
      name="Excluded Orders (Comma Separated)"
      label="Excluded Orders (Comma Separated)"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'woo.orders.exclude')?.Value}
      placeholder="Excluded Orders"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'woo.orders.exclude')}
    />
  </FormSection>
);

export default WooCommerceBusiness2CloudConnectorConfiguration;
