import { ChangeEvent } from 'react';

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';

const MailchimpBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the MailChimp integration</span>
    </div>
    <FormInput
      id="Data Center"
      name="Data Center"
      label="Data Center"
      required
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'mailchimp.dataCenter')?.Value}
      placeholder="Data Center"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'mailchimp.dataCenter')}
    />
    <FormInput
      id="User Id"
      name="User Id"
      label="User Id"
      required
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'mailchimp.userId')?.Value}
      placeholder="User Id"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'mailchimp.userId')}
    />
  </FormSection>
);

export default MailchimpBusiness2CloudConnectorConfiguration;
