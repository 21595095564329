import { ChangeEvent } from 'react';

import FormSection from '../../FormSection';
import FormInput from '../../FormInput';

const VendBusiness2CloudConnectorConfiguration = ({ configuration }: any) => (
  <FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the Vend integration</span>
    </div>
    <FormInput
      id="vend.domain"
      name="Domain"
      label="Domain"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'vend.domain')?.Value}
      placeholder="Domain"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'vend.domain')}
    />
  </FormSection>
);

export default VendBusiness2CloudConnectorConfiguration;
