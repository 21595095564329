import { ChangeEvent, createElement, Fragment, useContext, useEffect, useState } from "react";
import { Store } from "./../store/Store";
import { useParams } from "react-router-dom";
import { REDUCER_ACTION_SET } from "./../store/types";

import Form from "./Form";
import FormSection from "./FormSection";
import jwt from 'jwt-decode';
import FormInput from "./FormInput";
import B2CClientConnector from "./../interfaces/B2CClientConnector";
import Business2CloudApiService from "./../services/business2CloudApiService";
import B2CClientConnectorProperty from "./../interfaces/B2CClientConnectorProperty";
import B2CLicenceModel from "./../interfaces/B2CLicenceModel";
import ClientConnectorConfigurations from "./providers/configurations";
import ClientConnectorInitialisations from "./providers/initialize";

type ClientConnectorConfigurationFormModalProps = {
  clientConnector: B2CClientConnector | undefined;
  setShowContainerLoader: (show: boolean) => void;
  setClientConnector: (clientConnector: B2CClientConnector) => void;
  onSave: (clientConnector: B2CClientConnector | undefined) => void;
  onCancel: () => void;
}

const ClientConnectorConfigurationFormModal = ({
  clientConnector,
  setShowContainerLoader,
  setClientConnector,
  onSave,
  onCancel
}: ClientConnectorConfigurationFormModalProps) => {

  const { connectorType } = useParams();

  const [showCopied, setShowCopied] = useState(false);
  const [clientConnectorProperties, setClientConnectorProperties] = useState<B2CClientConnectorProperty[]>();
  const [clientConnectorLicenceModels, setClientConnectorLicenceModels] = useState<B2CLicenceModel[]>([]);
  const [licenceModelList, setLicenceList] = useState<B2CLicenceModel[]>();
  const [showLoader, setShowLoader] = useState(false);

  const targetConnectionString = clientConnectorProperties?.find((_) => _.Name === 'target.connectionString');
  const sourceConnectionString = clientConnectorProperties?.find((_) => _.Name === 'source.connectionString');

  const storeContext = useContext(Store);
  const store = storeContext.store;
  const dispatch = store.dispatch;

  const currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';

  const onSaveClick = async () => {
    setShowLoader(true);

    if (clientConnector != null && clientConnectorProperties != null) {
      var currentClientConnector = clientConnector;
      var editedClientConnectorProperties = [...clientConnectorProperties];

      if (clientConnector.Id) {
        await Business2CloudApiService.Instance.setClientConnectorAsync(store?.organisationSelected?.organisationId, clientConnector);
      } else {
        currentClientConnector = await Business2CloudApiService.Instance.addClientConnectorAsync(store?.organisationSelected?.organisationId, clientConnector);

        editedClientConnectorProperties = clientConnectorProperties.map((item) => ({
          ...item,
          ClientConnectorId: currentClientConnector.Id,
        }));
      }

      var updatePropertiesPromise = Business2CloudApiService.Instance.setClientConnectorPropertiesAsync(
        store?.organisationSelected?.organisationId,
        currentClientConnector.Id,
        editedClientConnectorProperties
      );

      let updateClientConnectorLicensesPromise =
        currentRole.indexOf('superadmin') >= 0
          ? Business2CloudApiService.Instance.setClientConnectorLicencesAsync(
            store?.organisationSelected?.organisationId,
            currentClientConnector.Id,
            clientConnectorLicenceModels)
          : new Promise<void>((resolve) => resolve());

      await Promise.all([updatePropertiesPromise, updateClientConnectorLicensesPromise]);

      if(dispatch)
        dispatch({ type: REDUCER_ACTION_SET.TRIGGER_CONNECTOR_TYPES_CACHE_BUST });
    }

    onSave(clientConnector);
  }

  const onCopyKeyToClipboardClick = () => {
    if (clientConnector?.Key) {
      navigator.clipboard.writeText(clientConnector.Key as string);

      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
    }
  };

  const onClientConnectorSchemaChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (clientConnector == null) return;
    if (clientConnectorProperties == null) return;

    let properties = [...clientConnectorProperties];
    let schema = properties.find((_) => _.Name === 'target.schema');

    if (!schema) {
      schema = {
        Name: 'target.schema',
        Value: event.target.value,
        ClientConnectorId: clientConnector.Id,
      } as B2CClientConnectorProperty;

      properties.push(schema);
    }

    schema.Value = event.target.value;

    setClientConnectorProperties(properties);
  };


  const onClientConnectorScheduleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (clientConnector == null) return;

    setClientConnector({
      ...clientConnector,
      Schedule: event.target.value,
    });
  };

  const onClientConnectorLicenceModelChange = (event: ChangeEvent<HTMLInputElement>, licenceModel: B2CLicenceModel) => {
    let tempSelectedClientConnectorLicenceModels = [...clientConnectorLicenceModels];

    if (event.target.checked) {
      tempSelectedClientConnectorLicenceModels.push(licenceModel);
    } else {
      let index = tempSelectedClientConnectorLicenceModels.findIndex((_) => _.Id === licenceModel.Id);
      tempSelectedClientConnectorLicenceModels.splice(index, 1);
    }

    setClientConnectorLicenceModels(tempSelectedClientConnectorLicenceModels);
  };

  const onClientConnectorTargetConnectionChange = (event: ChangeEvent<HTMLInputElement>, property: string) => {
    if (clientConnector == null) return;
    if (clientConnectorProperties == null) return;

    let properties = [...clientConnectorProperties];
    let connection = properties.find((_) => _.Name === 'target.connectionString')?.Value as any;

    if (!connection) {
      connection = {};

      properties.push({
        Name: 'target.connectionString',
        Value: connection,
        ClientConnectorId: clientConnector.Id,
      } as B2CClientConnectorProperty);
    }

    connection[property] = event.target.value;

    setClientConnectorProperties(properties);
  };

  const onClientConnectorSourceConnectionChange = (event: ChangeEvent<HTMLInputElement>, property: string) => {
    if (clientConnector == null) return;
    if (clientConnectorProperties == null) return;

    let properties = [...clientConnectorProperties];
    let connection = properties.find((_) => _.Name === 'source.connectionString')?.Value as any;

    if (!connection) {
      connection = {};

      properties.push({
        Name: 'source.connectionString',
        Value: connection,
        ClientConnectorId: clientConnector.Id,
      } as B2CClientConnectorProperty);
    }

    connection[property] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setClientConnectorProperties(properties);
  };

  const onClientConnectorPropertyChange = (event: ChangeEvent<HTMLInputElement> | any, propertyName: string) => {
    if (clientConnector == null) return;
    if (clientConnectorProperties == null) return;

    let properties = [...clientConnectorProperties];
    let property = properties.find((_) => _.Name === propertyName);

    if (!property) {
      property = {
        Name: propertyName,
        Value: event?.target?.value || event,
        ClientConnectorId: clientConnector.Id,
      } as B2CClientConnectorProperty;

      properties.push(property);
    }

    property.Value = event?.target?.value;

    setClientConnectorProperties(properties);
  };

  const onClientConnectorNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (clientConnector == null) return;

    setClientConnector({
      ...clientConnector,
      Name: event.target.value,
    });
  };

  useEffect(() => {
    if (!clientConnector) return;

    setShowContainerLoader(true);

    let currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';

    let clientConnectorPropertiesPromise = Business2CloudApiService.Instance.getClientConnectorPropertiesAsync(
      store?.organisationSelected?.organisationId,
      clientConnector.Id);
    let clientConnectorLicensesPromise = Business2CloudApiService.Instance.getClientConnectorLicencesAsync(
      store?.organisationSelected?.organisationId,
      clientConnector.Id);

    let connectorLicensesPromise =
      currentRole.indexOf('superadmin') >= 0
        ? Business2CloudApiService.Instance.getAllClientConnectorLicensesAsync(store?.organisationSelected?.organisationId, connectorType as string)
        : new Promise<[]>((resolve) => resolve([]));

    Promise.all([clientConnectorPropertiesPromise, clientConnectorLicensesPromise, connectorLicensesPromise]).then(responses => {
      setClientConnectorProperties(responses[0]);
      setClientConnectorLicenceModels(responses[1]);
      setLicenceList(responses[2]);

      setShowContainerLoader(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConnector])

  if (clientConnector && !clientConnector.Id && connectorType && (ClientConnectorInitialisations as any)[connectorType.toLowerCase()])
    return <Fragment>
      {createElement(
        (ClientConnectorInitialisations as any)[connectorType.toLowerCase()],
        {
          configuration: {
            clientConnector,
            clientConnectorProperties,
            sourceConnectionString,
            onClientConnectorPropertyChange,
            onClientConnectorSourceConnectionChange,
            onSave,
            onCancel
          }
        })}
    </Fragment>;

  return <Form onSubmit={onSaveClick}>
    <div className="modal modal-lg">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <h5 className="modal-title">{clientConnector?.Name || clientConnector?.Key}</h5>
              <span className="light-text">Connector properties</span>
            </div>
          </div>
          <div className="modal-body">
            <FormSection>
              <div className="form-section-header">
                <h5>General</h5>
                <span>General information about this connector</span>
              </div>
              {!!clientConnector?.Id && currentRole.indexOf('superadmin') >= 0 && (
                <div className="fancy-list fancy-list__vertical">
                  <div className="disabled">
                    <span className="d-flex flex-row align-items-center">
                      <span className="d-flex flex-column flex-grow-1 overflow-hidden">
                        <label>Key</label>
                        <span className="light-text ellipse">{clientConnector?.Key}</span>
                      </span>
                      {showCopied && (
                        <span className="ms-auto btn btn-success btn-xs btn-round ms-auto">
                          <span className="material-symbols-rounded">check</span>
                        </span>
                      )}
                      {!showCopied && (
                        <button className="ms-auto btn btn-outline-secondary btn-xs btn-round ms-auto" onClick={onCopyKeyToClipboardClick}>
                          <span className="material-symbols-rounded">content_copy</span>
                        </button>
                      )}
                    </span>
                  </div>
                  <div className="disabled">
                    <label>Last manually refreshed</label>
                    <span className="light-text">
                      {!!clientConnector?.Log?.ManuallyTriggeredAtUtc ? new Date(clientConnector?.Log?.ManuallyTriggeredAtUtc).toUTCString() : 'Never'}
                    </span>
                  </div>
                  <div className="disabled">
                    <label>Last automatically refreshed</label>
                    <span className="light-text">
                      {!!clientConnector?.Log?.AutomaticallyTriggeredAtUtc ? new Date(clientConnector?.Log?.AutomaticallyTriggeredAtUtc).toUTCString() : 'Never'}
                    </span>
                  </div>
                </div>
              )}
              <FormInput
                id="name"
                name="name"
                label="Name"
                required={true}
                value={clientConnector?.Name}
                placeholder="name"
                onChange={onClientConnectorNameChange} />
              <FormInput
                id="schema"
                name="schema"
                label="Schema"
                required={false}
                value={clientConnectorProperties?.find((_) => _.Name === 'target.schema')?.Value}
                placeholder="schema"
                onChange={onClientConnectorSchemaChange}
              />
              {currentRole.indexOf('superadmin') >= 0 && (
                <FormInput
                  id="schedule"
                  name="schedule"
                  label="Schedule (CRON Expression)"
                  required={false}
                  value={clientConnector?.Schedule}
                  placeholder="schedule"
                  onChange={onClientConnectorScheduleChange}
                />
              )}
              {!!clientConnector?.Id && currentRole.indexOf('superadmin') >= 0 && (
                <>
                  <div className="form-section-control gap-2">
                    <label htmlFor="licenses">Licences</label>
                    <div className="fancy-list">
                      {licenceModelList?.map((licenceModel, index) => {
                        const selected = clientConnectorLicenceModels?.find((_) => _.Id === licenceModel.Id);
                        const disabled = licenceModel.Base && !!selected;

                        return (
                          <div key={licenceModel.Id} className={`flex flex-column ${disabled ? 'disabled' : ''}`}>
                            <div key={index} className={`flex flex-row gap-2 align-items-center ${selected ? 'selected' : ''}`}>
                              <input
                                autoComplete="off"
                                type="checkbox"
                                className="form-check-input"
                                id={licenceModel.Id.toString()}
                                checked={!!selected}
                                disabled={disabled}
                                onChange={(event) => onClientConnectorLicenceModelChange(event, licenceModel)}
                              />
                              {licenceModel.Description}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </FormSection>
            {
              connectorType &&
              clientConnector &&
              clientConnectorProperties &&
              clientConnectorLicenceModels &&
              (ClientConnectorConfigurations as any)[connectorType.toLowerCase()] &&
              createElement(
                (ClientConnectorConfigurations as any)[connectorType.toLowerCase()],
                {
                  configuration: {
                    clientConnectorProperties,
                    sourceConnectionString,
                    onClientConnectorPropertyChange,
                    onClientConnectorSourceConnectionChange
                  }
                })
            }
            <FormSection>
              <div className="form-section-header">
                <h5>{connectorType === 'sqlserver' ? 'Target connection' : 'Connection'}</h5>
                <span>
                  {connectorType === 'sqlserver' ? 'Connection' : 'Cutsom connection'} details for the {connectorType === 'sqlserver' ? 'target' : ''} database used to <b>store</b> the connector
                  data.
                  <br />
                  <p className="lighter-text">
                    <small>
                      Leave blank to allow <b>Business2Cloud</b> to store the connector data.
                    </small>
                  </p>
                </span>
              </div>
              <FormInput
                id="target data source"
                name="target data source"
                label="Host"
                required={false}
                value={targetConnectionString?.Value['data source']}
                placeholder="Host"
                onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'data source')}
              />
              <FormInput
                id="target initial catalog"
                name="target initial catalog"
                label="Database"
                required={false}
                value={targetConnectionString?.Value['initial catalog']}
                placeholder="Database"
                onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'initial catalog')}
              />
              <div className="grid grid-col-1/2">
                <FormInput
                  id="target user id"
                  name="target user id"
                  label="Username"
                  required={false}
                  value={targetConnectionString?.Value['user id']}
                  placeholder="Username"
                  onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'user id')}
                />
                <FormInput
                  id="target password"
                  name="target password"
                  label="Password"
                  type="password"
                  required={false}
                  value={targetConnectionString?.Value['password']}
                  placeholder="Password"
                  onChange={(e: any) => onClientConnectorTargetConnectionChange(e, 'password')}
                />
              </div>
            </FormSection>
          </div>
          <div className="modal-footer">
            <button className="btn btn-sm btn-outline-dark" onClick={onCancel}>
              Cancel
            </button>
            <button type="submit" className="btn btn-sm btn-primary">
              Save
            </button>
          </div>
          {showLoader && (
            <div className="modal-loader">
              <svg className="spinner primary" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="circle" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </div>
  </Form>
};

export default ClientConnectorConfigurationFormModal;