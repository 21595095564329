import { ChangeEvent, Fragment, useContext, useState } from 'react';
import { Store } from "./../../../store/Store";

import FormInput from '../../FormInput';
import FormSection from '../../FormSection';
import jwt from 'jwt-decode';
import toast from "react-hot-toast";
import Spinner from "./../../../components/Spinner";

const PowerOfficeBusiness2CloudConnectorConfiguration = ({ configuration }: any) => {
  const store = useContext(Store).store;

  const [isLoading, setIsLoading] = useState(false);
  const [organisationNumber, setOrganisationNumber] = useState<string>();

  const onConnectClick = () => {
    setIsLoading(true);

    var request = fetch(`${process.env.REACT_APP_B2C_API_URL}/api/authorise/poweroffice?state=${store?.organisationSelected?.organisationId}`, {
      method: 'post',
      body: JSON.stringify({ organisationNumber: organisationNumber }),
      headers: { 'Content-Type': 'application/json' },
    });

    request
      .then(response => response.json().then(responseContent => {
        if (responseContent.url) {
          window.location.replace(responseContent.url);
        }
      }))
      .catch(() => {
        toast.error("Something seems to have gone unexpectedly wrong.");
        setIsLoading(false);
      });
  }

  let currentRole = jwt<any>(store?.jwt)?.Role?.toLowerCase() ?? '';

  if (!configuration.clientConnector?.Id)
    return <FormSection>
      <div className="form-section-header">
        <h5>Integration Settings</h5>
        <span>Details for the Power Office API integration</span>
      </div>
      <div className="tw-flex tw-items-end tw-content-end tw-relative">
        <FormInput
          inputClassName="!tw-pe-40"
          id="poweroffice.organisationNumber"
          type="text"
          name="Organisation Number"
          label="Organisation Number"
          value={organisationNumber}
          placeholder="Organisation Number"
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => setOrganisationNumber(event.currentTarget.value)}
        />
        <button disabled={isLoading} className="btn btn-primary tw-absolute tw-right-1 tw-bottom-1 tw-z-10 tw-w-36 tw-justify-center" title="Connect" onClick={onConnectClick} type="button">
          {isLoading && <Spinner />}
          {!isLoading && <Fragment><span className="material-symbols-rounded">link</span> Connect</Fragment>}
        </button>
      </div>
    </FormSection>

  return (<FormSection>
    <div className="form-section-header">
      <h5>Integration Settings</h5>
      <span>Details for the Power Office API integration</span>
    </div>
    <FormInput
      inputClassName="!tw-pe-40"
      id="poweroffice.organisationNumber"
      type="text"
      name="Organisation Number"
      label="Organisation Number"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.organisationNumber')?.Value}
      placeholder="Organisation Number"
      required
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.organisationNumber')}
    />
    <FormInput
      inputClassName="!tw-pe-40"
      id="poweroffice.clientKey"
      type="password"
      name="Client Key"
      label="Client Key"
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.clientKey')?.Value}
      placeholder="Client Key"
      required
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.clientKey')}
    />
    <FormInput
      id="poweroffice.startDate"
      name="Start Date"
      label="Start Date | The date from which to query data"
      required={true}
      value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.startDate')?.Value}
      placeholder="YYYY-MM-DD"
      onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.startDate')}
    />
    {currentRole === 'superadmin' &&
      <FormInput
        id="poweroffice.apiPath"
        name="API Path"
        label="API Path"
        value={configuration.clientConnectorProperties?.find((_: any) => _.Name === 'poweroffice.apiPath')?.Value}
        placeholder="API Path"
        onChange={(event: ChangeEvent<HTMLInputElement>) => configuration.onClientConnectorPropertyChange(event, 'poweroffice.apiPath')}
      />
    }
  </FormSection>
  );
};

export default PowerOfficeBusiness2CloudConnectorConfiguration;
